<template>
  <div style="display: flex;color: black;">
    <div
        @mousedown="recording = true"
        @mouseup="recording = false"
        :class="{recording}">
      <VoiceRecognition :recording="recording"
                        @spoken="spoken($event)"
                        @recognized="recognized($event)"/>
    </div>
    <div style="flex-grow:1; padding: 12px;">
      <h1>All Cards</h1>
      <p v-for="cardName in cardNames" :key="cardName"
         style="padding: 0; margin: 0;">
        {{ cardName }}
      </p>
    </div>
    <div style="flex-grow:1; padding: 12px;">
      <h1>Transcript</h1>
      <span v-for="(word, idx) in recognizedWords" :key="word + idx" style="color: black">{{ word }} | </span>
    </div>
    <div style="flex-grow:1; padding: 12px;">
      <h1>Recognized</h1>
      <p v-for="cardName in recognizedCardNames" :key="'r_' + cardName">
        {{ cardName }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import VoiceRecognition from "@/components/VoiceRecognition";

export default {
  components: {VoiceRecognition},
  data() {
    return {
      recording: false,
      recognizedWords: [],
      recognizedCardNames: []
    }
  },
  computed: {
    ...mapGetters(['allCards']),
    cardNames() {
      return this.allCards.map(c => this.$i18n.t('cards.' + c.name))
    },
  },
  methods: {
    ...mapActions(['reset']),
    spoken(word) {
      if (!this.recognizedWords.indexOf(word) >= 0)
        this.recognizedWords.push(word)
    },
    recognized(cardName) {
      if (this.recognizedCardNames.indexOf(cardName) < 0)
        this.recognizedCardNames.push(cardName)
    }
  },
  mounted() {
    this.reset();
  }
}
</script>

<style scoped>
.recording {
  color: red
}
</style>